import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Text from "components/Text";
import Headline from "components/Headline";

export const ContentWrapperStyled = styled("div")({
  position: "relative",
  padding: "0 45px 0 45px",
  width: "100%",
  textAlign: "center",

  [media(0, "mobile")]: {
    padding: "0 10px 0 10px",
    width: "100%",
  },
});

export const HeadlineStyled = styled(Headline)({
  marginBottom: 20,
});

export const TimeTextStyled = styled("div")({
  fontSize: "small",
  textTransform: "uppercase",
  marginBottom: -10,
  letterSpacing: "-0.01em",
});

export const TimeNumberStyled = styled("div")(({ theme }) => ({
  fontSize: 40,
  color: theme.colorSecondary,
}));

export const TimeBoxStyled = styled("div")(({ theme }) => ({
  margin: 8,
  padding: "1em .5em",
  textTransform: "uppercase",
  background:
    theme.name === "alternative" ? theme.backgroundSecondary : "white",
  width: 110,
  color: theme.colorTertiary,
  fontVariantNumeric: "tabular-nums",
  // trick to align in 2 + 2 grid instead of 3 + 1 below 600px
  [media(390, 600)]: {
    width: 155,
  },
}));

export const CountdownWrapperStyled = styled("div")(({ theme }) => ({
  padding: "50px 0 25px",
  background: theme.sectionTextBackground,
  position: "relative",
  zIndex: 1,
}));

export const TextStyled = styled(Text)({});
