import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import SwitchTheme from 'components/SwitchTheme';
import Redactor from 'components/Redactor';
import CountdownNow from 'react-countdown-now';
import Grid from 'components/Grid';
import GridItem from 'components/GridItem';

import {
  ContentWrapperStyled,
  CountdownWrapperStyled,
  TextStyled,
  HeadlineStyled,
  TimeTextStyled,
  TimeNumberStyled,
  TimeBoxStyled
} from './CountdownStyled';

const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric'
});

const Countdown = ({ pageBuilder } /* :PageBuilderListInterface */) => {
  const {
    countdownHeadline,
    countdownDateTime,
    countdownText,
    countdownTheme
  } = pageBuilder; // ListItemInterface[]

  const countdown = useRef();

  const date = new Date(Number(countdownDateTime) * 1e3);

  const textContent = countdownText?.content;

  return (
    <SwitchTheme name={countdownTheme}>
      <CountdownWrapperStyled
        style={{
          display:
            countdown.current && countdown.current.isCompleted()
              ? 'none'
              : undefined
        }}
      >
        <Grid>
          <GridItem mobile={1} tabletFluid={1}>
            <ContentWrapperStyled>
              <HeadlineStyled>
                {countdownHeadline.replace(
                  /\$date/,
                  dateTimeFormatter.format(date)
                )}
              </HeadlineStyled>
              <TextStyled margin="0 0 50px 0">
                {textContent ? (
                  <Redactor content={textContent} parseType="content" />
                ) : null}
                <CountdownNow
                  date={date}
                  ref={countdown}
                  renderer={({ days, hours, minutes, seconds }) => (
                    <div
                      style={{
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                      }}
                    >
                      <TimeBoxStyled>
                        <TimeNumberStyled>
                          {String(days).padStart(2, '0')}
                        </TimeNumberStyled>
                        <TimeTextStyled>Tage</TimeTextStyled>
                      </TimeBoxStyled>
                      <TimeBoxStyled>
                        <TimeNumberStyled>
                          {String(hours).padStart(2, '0')}
                        </TimeNumberStyled>
                        <TimeTextStyled>Stunden</TimeTextStyled>
                      </TimeBoxStyled>
                      <TimeBoxStyled>
                        <TimeNumberStyled>
                          {String(minutes).padStart(2, '0')}
                        </TimeNumberStyled>
                        <TimeTextStyled>Minuten</TimeTextStyled>
                      </TimeBoxStyled>
                      <TimeBoxStyled>
                        <TimeNumberStyled>
                          {String(seconds).padStart(2, '0')}
                        </TimeNumberStyled>
                        <TimeTextStyled>Sekunden</TimeTextStyled>
                      </TimeBoxStyled>
                    </div>
                  )}
                />
              </TextStyled>
            </ContentWrapperStyled>
          </GridItem>
        </Grid>
      </CountdownWrapperStyled>
    </SwitchTheme>
  );
};

export default Countdown;

export const query = graphql`
  fragment PageBuilderCountdownQuery on Craft_PageBuilderCountdown {
    countdownHeadline
    countdownText {
      content
    }
    countdownDateTime
    countdownTheme
  }
`;
